<template>
  <div style="height: 100%; width: 100%">
    <div v-show="isLoading" class="text-center text-danger my-2">
      <b-spinner variant="danger"></b-spinner><br />
      <strong>{{ $t("GENERAL.PLEASE_WAIT") }}</strong>
    </div>
    <div v-show="!isLoading" id="meet"></div>
  </div>
</template>

<script>
import {
  getUserLastName,
  getUserName,
  getUserId,
  getUserType
} from "@/common/jwt.service";
import KTUtil from "@/assets/js/util";
import ApiService from "@/common/api.service";

export default {
  name: "OnlineClass",
  data() {
    return {
      student_class_id: null,
      subject_id: null,
      api: null,
      isLoading: true,
      conferenceDetail: {
        lecturer: null,
        subject: null,
        class: null,
        conferenceId: null
      }
    };
  },
  mounted() {
    ApiService.get(
      this.userType() +
        "/online-class-details/" +
        this.student_class_id +
        "/" +
        this.subject_id
    ).then(({ data }) => {
      this.conferenceDetail = data;
      this.isLoading = false;
      this.initConference(
        this.conferenceDetail.conferenceId,
        this.userType() === "student"
          ? this.$t("ONLINE_CLASS.TITLE", {
              subject: this.conferenceDetail.subject,
              lecturer: this.conferenceDetail.lecturer
            })
          : this.conferenceDetail.class
      );
    });
  },
  methods: {
    initConference(roomId, title) {
      const domain = "meet.jit.si";
      const options = {
        roomName: roomId,
        width: "100%",
        height: this.getVideoHeight(),
        userInfo: {
          email: this.userEmail,
          displayName: this.userName
        },
        parentNode: document.querySelector("#meet"),
        onload: (this.isLoading = false),
        configOverwrite: {
          startWithAudioMuted: this.userType() === "student",
          startWithVideoMuted: true,
          enableWelcomePage: false,
          disableProfile: true,
          prejoinPageEnabled: false,
          enableAutomaticUrlCopy: false,
          disableInviteFunctions: true,
          doNotStoreRoom: true,
          remoteVideoMenu: {
            disableKick: true
          },
          disableRemoteMute: this.userType() === "student",
          enableClosePage: false
        },
        interfaceConfigOverwrite: {
          SETTINGS_SECTIONS: ["devices", "language"],
          SHOW_CHROME_EXTENSION_BANNER: false,
          SHOW_JITSI_WATERMARK: false,
          SHOW_POWERED_BY: false,
          SHOW_PROMOTIONAL_CLOSE_PAGE: false,
          JITSI_WATERMARK_LINK: "https://dawat.edu.af",
          MOBILE_APP_PROMO: false,
          PROVIDER_NAME: "Dawat University",
          CLOSE_PAGE_GUEST_HINT: false,
          TOOLBAR_BUTTONS: [
            "microphone",
            "camera",
            "closedcaptions",
            "desktop",
            "fullscreen",
            "fodeviceselection",
            "hangup",
            "chat",
            "recording",
            "etherpad",
            "settings",
            "raisehand",
            "videoquality",
            "filmstrip",
            "stats",
            "tileview",
            "videobackgroundblur",
            "download"
          ]
        }
      };
      // eslint-disable-next-line no-undef
      this.api = new JitsiMeetExternalAPI(domain, options);
      this.api.executeCommand("subject", title);
    },
    userType() {
      return getUserType();
    },
    getContentHeight() {
      let height;
      height = KTUtil.getViewPort().height;
      if (KTUtil.getByID("kt_header"))
        height = height - KTUtil.actualHeight("kt_header");
      if (KTUtil.getByID("kt_subheader"))
        height = height - KTUtil.actualHeight("kt_subheader");
      if (KTUtil.getByID("kt_footer"))
        height = height - parseInt(KTUtil.css("kt_footer", "height"));
      if (KTUtil.getByID("kt_content")) {
        height =
          height -
          parseInt(KTUtil.css("kt_content", "padding-top")) -
          parseInt(KTUtil.css("kt_content", "padding-bottom"));
      }
      return height;
    },
    getVideoHeight() {
      let height;
      let parentEl = KTUtil.getByID("kt_chat_content");
      let chatEl = KTUtil.find(parentEl, ".kt-chat");
      let portletHeadEl = KTUtil.find(
        parentEl,
        ".kt-portlet > .kt-portlet__head"
      );
      let portletBodyEl = KTUtil.find(
        parentEl,
        ".kt-portlet > .kt-portlet__body"
      );
      let portletFootEl = KTUtil.find(
        parentEl,
        ".kt-portlet > .kt-portlet__foot"
      );
      if (KTUtil.isInResponsiveRange("desktop")) {
        height = this.getContentHeight();
      } else {
        height = KTUtil.getViewPort().height;
      }
      if (chatEl) {
        height =
          height -
          parseInt(KTUtil.css(chatEl, "margin-top")) -
          parseInt(KTUtil.css(chatEl, "margin-bottom"));
        height =
          height -
          parseInt(KTUtil.css(chatEl, "padding-top")) -
          parseInt(KTUtil.css(chatEl, "padding-bottom"));
      }
      if (portletHeadEl) {
        height = height - parseInt(KTUtil.css(portletHeadEl, "height"));
        height =
          height -
          parseInt(KTUtil.css(portletHeadEl, "margin-top")) -
          parseInt(KTUtil.css(portletHeadEl, "margin-bottom"));
      }
      if (portletBodyEl) {
        height =
          height -
          parseInt(KTUtil.css(portletBodyEl, "margin-top")) -
          parseInt(KTUtil.css(portletBodyEl, "margin-bottom"));
        height =
          height -
          parseInt(KTUtil.css(portletBodyEl, "padding-top")) -
          parseInt(KTUtil.css(portletBodyEl, "padding-bottom"));
      }
      if (portletFootEl) {
        height = height - parseInt(KTUtil.css(portletFootEl, "height"));
        height =
          height -
          parseInt(KTUtil.css(portletFootEl, "margin-top")) -
          parseInt(KTUtil.css(portletFootEl, "margin-bottom"));
      }
      height = height - 5;
      return height + "px";
    }
  },
  created() {
    if (
      this.$route.params.student_class_id !== undefined &&
      this.$route.params.subject_id !== undefined
    ) {
      this.student_class_id = this.$route.params.student_class_id;
      this.subject_id = this.$route.params.subject_id;
    } else {
      this.$router.push({
        name: "dashboard"
      });
    }
  },
  destroyed() {
    this.api.dispose();
  },
  computed: {
    userName() {
      return (
        getUserName() +
        " " +
        (getUserLastName() !== null ? getUserLastName() : "")
      );
    },
    userEmail() {
      return getUserId() + "@dawat.edu.af";
    }
  }
};
</script>

<style scoped></style>
